<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ title }} coupon code</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link> \ {{ title }} coupon code
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="saveCouponCode()">
              <v-row>
                <v-col cols="12" md="12">
                  <h5>Promotion detail</h5>
                  <hr/>
                  <v-text-field placeholder="Coupon name" v-model="coupon.title" outlined :error="$v.coupon.title.$error" dense>
                    <template v-slot:label>
                      Promotion title <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.title.$error">This information is required</span>
                  <span class="text-danger" v-if="errors.title">{{errors.title[0]}}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>Description</label>
                  <ckeditor v-model="coupon.description" :config="editorConfig">
                  </ckeditor>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="couponId == null">
                  <h5>Coupon detail</h5>
                  <hr/>
                  <span class="font-weight-medium">Create a custom coupon or generate multiple in bulk?</span>
                  <v-switch v-model="coupon.is_bulk_coupon" :label="coupon.is_bulk_coupon ? 'Generate bulk coupons' : 'Coupon code'"
                            @change="bulkToggle">
                  </v-switch>
                </v-col>
                <v-col cols="6" v-if="couponId == null && show.bulkShow">
                  <v-text-field label="Bulk coupon prefix" outlined @input="countCouponPrefixLength"
                                dense :error="$v.coupon.bulk_coupon_prefix.$error" v-model="coupon.bulk_coupon_prefix">
                    <template v-slot:label>
                      Coupon prefix <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.bulk_coupon_prefix.$error">This information is required</span>
                </v-col>
                <v-col cols="6" v-if="couponId == null && show.bulkShow">
                  <v-text-field @blur="countCouponPrefixLength" label="Bulk coupon length"
                                outlined :error="$v.coupon.bulk_coupon_length.$error || errorMessage"
                                dense v-model="coupon.bulk_coupon_length">
                    <template v-slot:label>
                      Coupon length <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.bulk_coupon_length.$error">This information is required</span>
                  <span class="text-danger" v-if="errorMessage">{{errorMessage }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="showCouponCodeField">
                  <v-text-field label="Coupon code*" outlined dense :error="$v.coupon.coupon_code.$error" v-model="coupon.coupon_code">
                    <template v-slot:label>
                      Coupon code <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.coupon_code.$error">This information is required</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field outlined :error="$v.coupon.offered_coupon_qty.$error" dense type="number" v-model="coupon.offered_coupon_qty">
                    <template v-slot:label>
                      Coupon quantity <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.offered_coupon_qty.$error">This information should be number</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <h5>Discount settings</h5>
                  <hr/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select placeholder="Discount type" label="Discount type" :items="types" :error="$v.coupon.coupon_type.$error"
                            item-text="name" clearable item-value="value" outlined dense v-model="coupon.coupon_type">
                    <template v-slot:label>
                      Discount type <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.coupon.coupon_type.$error">This information is required</span>
                  <span class="text-danger" v-if="errors.coupon_type">{{errors.coupon_type[0] }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field outlined :error="$v.coupon.coupon_value.$error" dense v-model="coupon.coupon_value">
                    <template v-slot:label>
                      Discount <span v-if="coupon.coupon_type==='percentage'"> percentage </span> <span v-else>amount</span> <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.coupon_value.$error">This information is required</span>
                  <span class="text-danger" v-if="errors.coupon_value">{{errors.coupon_value[0]}}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-menu ref="menu" v-model="expiry_date_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="coupon.expiry_date" prepend-icon="" prepend-inner-icon="mdi-calendar"
                          label="Expiry date" readonly :error="$v.coupon.expiry_date.$error"
                          v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          Coupon expiry date <span class="text-danger">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker no-title v-model="coupon.expiry_date" :active-picker.sync="activePicker" min="1950-01-01"
                        @change="save">
                    </v-date-picker>
                  </v-menu>
                  <span class="text-danger" v-if="$v.coupon.expiry_date.$error">
                    This information is required</span>
                  <span class="text-danger" v-if="errors.expiry_date">{{errors.expiry_date[0]}}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type !== 'score'">
                  <v-select label="SCORE*" outlined dense item-text="name" :items="scores" item-value="id" :error="$v.coupon.score_id.$error" v-model="coupon.score_id">
                    <template v-slot:label> Applicable SCORE</template>
                  </v-select>
                  <span class="text-danger" v-if="$v.coupon.score_id.$error">Select Score</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <h5>Restrict promotion to product/enrolment type</h5>
                  <hr/>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select  placeholder="Promotion limitation" label="Promotion limitation" :items="product_restrictions"
                            item-text="name" clearable item-value="value" outlined dense v-model="coupon.product_restriction" @change="showRestrictionToApply()">
                    <template v-slot:label>
                      Restrict this promotion to certain exams/course or families? <span class="text-danger">*</span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="show.productShow">
                  <v-chip class="ma-2" close @click:close="removeProduct(index)" color="primary" v-if="productList.length > 0" v-for="(product, index) in productList" :key="index" label>
                    {{ product.name_with_subject_code }}
                  </v-chip>
                  <v-autocomplete @change="getProduct()" item-text="name_with_subject_code" class="ma-2" item-value="id"
                                  :error="$v.productList.$error" placeholder="Start searching by name or subject code" v-model="selectProducts"
                                  :items="products" :search-input.sync="productSearch" return-object outlined dense label="Select exam/course products" clearable>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name_with_subject_code }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name_with_subject_code"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select products <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.productList.$error">Exam</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="show.categoryShow">
                  <v-chip class="ma-2" close @click:close="removeCategory(index)" color="primary" v-if="categoryList.length > 0" v-for="(category, index) in categoryList" :key="index" label>
                    {{ category.name }}
                  </v-chip>
                  <v-autocomplete @change="getCategories()" item-text="name" class="ma-2" item-value="id"
                                  :error="$v.categoryList.$error" v-model="selectCategories" :items="categories"
                                  :search-input.sync="categorySearch" return-object outlined dense
                                  label="Select categories" placeholder="Start searching by name" clearable>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select categories <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.categoryList.$error">Select categories</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="show.instrumentShow">
                  <v-chip class="ma-2" close @click:close="removeInstrument(index)" color="primary" v-if="instrumentList.length > 0" v-for="(item, index) in instrumentList" :key="index" label>
                    {{ item.name }}
                  </v-chip>
                  <v-autocomplete @change="getInstruments()" item-text="name" class="ma-2" item-value="id"
                                  :error="$v.instrumentList.$error" placeholder="Start searching by name" v-model="selectInstruments"
                                  :items="instruments" :search-input.sync="instrumentSearch" return-object outlined
                                  dense label="Instrument" clearable>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select instrument families<span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.instrumentList.$error">Select instrument families</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="show.syllabusShow">
                  <v-chip class="ma-2" close @click:close="removeSyllabus(index)"
                          color="primary" v-if="syllabusList.length > 0" v-for="(item, index) in syllabusList" :key="index" label>
                    {{ item.name }}
                  </v-chip>
                  <v-autocomplete @change="getSyllabuses()" :error="$v.syllabusList.$error" item-text="name"
                                  class="ma-2" item-value="id" v-model="selectSyllabus" :items="syllabuses" :search-input.sync="syllabusSearch" return-object outlined dense label="Select syllabuses"
                                  clearable>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select syllabuses <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.syllabusList.$error">Select syllabuses</span>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <h5>Cart/Checkout settings</h5>
                  <hr/>
                </v-col>
                <v-col cols="4">
                  <span class="font-weight-medium">Restrict usage to one coupon per enroler</span>
                  <v-switch
                      v-model="coupon.is_one_time_use"
                      :label="coupon.is_one_time_use ? 'Yes' : 'No'"
                      @change="bulkToggle"
                  ></v-switch>
                </v-col>
                <v-col cols="4">
                  <span class="font-weight-medium">Discount applicable to all candidates in the cart?</span>
                  <v-switch @change="changeApplyDiscount()" v-model="coupon.apply_discount_to_all"
                            :label="coupon.apply_discount_to_all ? 'Yes, this applies to all eligible candidates' : 'No, this applies only to a single candidate in the cart'">
                  </v-switch>
                </v-col>
                <v-col cols="3" v-if="!coupon.apply_discount_to_all">
                  <v-text-field @blur="validateInputForMaxCandidates" ref="inputField" type="number" label="Enter number of candidates to limit promotions in a single cart" dense outlined v-model="coupon.limit_max_candidates"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="coupon.applies_for_min_cart_amount" :items="apply_option" item-text="name"
                      item-value="value" outlined dense @change="cartAmountToggle" label="Minimum cart amount required for coupon eligibility"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="show.cartAmountShow">
                  <v-text-field outlined dense
                      :error="$v.coupon.min_cart_amount.$error" v-model="coupon.min_cart_amount" label="Minimum cart amount required for the coupon to apply">
                    <template v-slot:label>
                      Minimum cart amount required for the coupon to apply <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.min_cart_amount.$error">This information is required</span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <span class="font-weight-medium">Status</span>
                  <v-switch v-model="coupon.is_active" label="Status" :label="coupon.is_active ? 'Active' : 'Inactive'"></v-switch>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn text x-large @click="back"> Cancel</v-btn>
                  <v-btn dark x-large @click="saveCouponCode()" :loading="loading">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" scrollable max-width="600">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <v-col cols="12" sm="6" md="12">
                <h4>{{ promptMessage }}</h4>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import ScoreService from "@/services/score/score/ScoreService";
import CouponCodeService from "@/services/couponCode/CouponCodeService";
import CategoryService from "@/services/product/category/CategoryService";
import {required, requiredIf, numeric} from "vuelidate/lib/validators";
import ProductService from "@/services/product/ProductService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";

const syllabus = new SyllabusService();
const instrument = new InstrumentService();
const product = new ProductService();
const category = new CategoryService();
const coupon = new CouponCodeService();
const score = new ScoreService();
export default {
  validations: {
    coupon: {
      title: {required},
      coupon_type: {required},
      expiry_date: {required},
      coupon_value: {required},
      offered_coupon_qty: {required},
      score_id: {
        required: requiredIf(function (nestedModel) {
          return this.currentUser.access_type === "score";
        }),
      },

      bulk_coupon_prefix: {
        required: requiredIf(function (nestedModel) {
          return this.show.bulkShow === true && this.couponId == null;
        }),
      },
      bulk_coupon_length: {
        required: requiredIf(function (nestedModel) {
          return this.show.bulkShow === true && this.couponId == null;
        }),
      },
      min_cart_amount: {
        required: requiredIf(function (nestedModel) {
          return this.show.cartAmountShow === true;
        }),
      },
      coupon_code: {
        required: requiredIf(function (nestedModel) {
          return this.show.bulkShow === false && this.couponId == null;
        }),
      },
    },
    categoryList: {
      required: requiredIf(function (nestedModel) {
        return this.show.categoryShow === true;
      }),
    },
    productList: {
      required: requiredIf(function (nestedModel) {
        return this.show.productShow === true;
      }),
    },
    instrumentList: {
      required: requiredIf(function (nestedModel) {
        return this.show.instrumentShow === true;
      }),
    },
    syllabusList: {
      required: requiredIf(function (nestedModel) {
        return this.show.syllabusShow === true;
      }),
    },
  },
  data() {
    return {
      edit: false,
      title: "",
      expiry_date_menu: false,
      categories: [],
      products: [],
      instruments: [],
      syllabuses: [],
      activePicker: null,
      scores: [],
      errors: [],
      coupon: {
        title: "",
        expiry_date: "",
        coupon_type: "",
        coupon_value: "",
        offered_coupon_qty: "",
        description: "",
        coupon_code: "",
        bulk_coupon_prefix: "",
        bulk_coupon_length: "",
        score_id: null,
        min_cart_amount: "",
        applies_to_category: false,
        applies_to_product: false,
        applies_to_syllabus: false,
        applies_to_instrument: false,
        applicable_categories: [],
        applicable_products: [],
        applicable_instruments: [],
        applicable_syllabuses: [],
        applies_for_min_cart_amount: false,
        is_bulk_coupon: false,
        is_one_time_use: false,
        is_active: true,
        apply_discount_to_all: true,
        product_restriction:'any',
        limit_max_candidates:1,
      },
      loading: false,
      types: [
        {name: "Fixed Price", value: "fixed"},
        {name: "Percentage", value: "percentage"},
      ],
      product_restrictions: [
        {name: "Exam/courses", value: "product"},
        {name: "Categories", value: "categories"},
        {name: "Instruments", value: "instruments"},
        {name: "Syllabus", value: "syllabus"},
        {name: "Any", value:"any"},
      ],
      apply_option: [
        {name: "Yes", value: 1},
        {name: "No", value: 0},
      ],
      show: {
        categoryShow: false,
        productShow: false,
        syllabusShow: false,
        instrumentShow: false,
        cartAmountShow: false,
        bulkShow: false,
      },

      editorConfig: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
        versionCheck: false
      },
      selectCategories: {},
      selectProducts: {},
      selectInstruments: {},
      selectSyllabus: {},
      productSearch: "",
      instrumentSearch: "",
      syllabusSearch: "",
      categorySearch: "",
      productList: [],
      categoryList: [],
      syllabusList: [],
      instrumentList: [],
      errorMessage: "",
      showCouponCodeField: false,
      promptMessage: '',
      dialog: false
    };
  },
  methods: {
    back() {
      this.$tabs.close();
      this.$router.push({
        name: "coupon-code",
      });
    },
    closeDialog() {
      this.dialog = false;
    },
    getCategories() {
      let categoryExist = this.categoryList.some(
          (category) => category.id === this.selectCategories.id
      );
      if (!categoryExist) {
        this.categoryList.push(this.selectCategories);
      }

      this.selectCategories = {};
      this.categorySearch = null;
    },
    getProduct() {
      let productExist = this.productList.some(
          (product) => product.id === this.selectProducts.id
      );
      if (!productExist) {
        this.productList.push(this.selectProducts);
      }
      this.selectProducts = {};
      this.productSearch = null;
    },

    getInstruments() {
      let instrumentExist = this.instrumentList.some(
          (instrument) => instrument.id === this.selectInstruments.id
      );
      if (!instrumentExist) {
        this.instrumentList.push(this.selectInstruments);
      }
      this.selectInstruments = {};
      this.instrumentSearch = null;
    },
    getSyllabuses() {
      let syllabusExist = this.syllabusList.some(
          (syllabus) => syllabus.id === this.selectSyllabus.id
      );
      if (!syllabusExist) {
        this.syllabusList.push(this.selectSyllabus);
      }
      this.selectSyllabus = {};
      this.syllabusSearch = null;
    },
    saveCouponCode() {
      if (this.currentUser.access_type === 'score') {
        this.coupon.score_id = this.currentUser.score_id;
      }
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.categoryList != null) {
          this.coupon.applicable_categories = this.categoryList.map((obj) => obj.id);
          this.coupon.applicable_categories = this.coupon.applicable_categories.toString();
        }
        if (this.productList != null) {
          this.coupon.applicable_products = this.productList.map((obj) => obj.id);
          this.coupon.applicable_products = this.coupon.applicable_products.toString();
        }
        if (this.instrumentList != null) {
          this.coupon.applicable_instruments = this.instrumentList.map((obj) => obj.id);
          this.coupon.applicable_instruments = this.coupon.applicable_instruments.toString();
        }
        if (this.syllabusList != null) {
          this.coupon.applicable_syllabuses = this.syllabusList.map((obj) => obj.id);
          this.coupon.applicable_syllabuses = this.coupon.applicable_syllabuses.toString();
        }
        if (this.couponId != null) {
        this.loading=true;
          coupon
              .update(this.couponId, this.coupon)
              .then((response) => {
                this.couponCodeDetail();
                this.$snotify.success("Coupon code updated");
                this.loading = false;
              })
              .catch((error) => {
                this.errors = error.response.data.errors;
              });
        } else {
          this.loading=true;
          coupon
              .create(this.coupon)
              .then((response) => {
                this.$snotify.success("Coupon code added");
                this.back();
                this.coupon = {};
                this.$v.$reset();
                this.loading = false;
              })
              .catch((error) => {
                this.errors = error.errors;
              });
        }
      }
    },
    couponCodeDetail() {
      if (this.couponId != null || this.couponId !== undefined) {
        this.title = "Update";
        this.edit = true;
        coupon.show(this.couponId).then((response) => {

          this.coupon = response.data.couponCode;
          this.productToggle();
          this.categoryToggle();
          this.instrumentToggle();
          this.syllabusToggle();
          if(!this.coupon.applies_to_category && !this.coupon.applies_to_instrument && !this.coupon.applies_to_syllabus ){
            this.coupon.product_restriction='any';
          }
          if (this.coupon.applicable_categories_list) {
            this.categoryList = this.coupon.applicable_categories_list;
          } else {
            this.categoryList = [];
          }
          if (this.coupon.applicable_instruments_list) {
            this.instrumentList = this.coupon.applicable_instruments_list;
          } else {
            this.instrumentList = [];
          }
          if (this.coupon.applicable_syllabuses_list) {
            this.syllabusList = this.coupon.applicable_syllabuses_list;
          } else {
            this.syllabusList = [];
          }
          if (this.coupon.applicable_products_list) {
            this.productList = this.coupon.applicable_products_list;
          } else {
            this.productList = [];
          }


          this.categoryToggle();
          this.bulkToggle();
          this.cartAmountToggle();
        });
      } else {
        this.title = "Create";
        this.coupon = {
          title: "",
          expiry_date: "",
          coupon_type: "",
          coupon_value: "",
          offered_coupon_qty: "",
          description: "",
          coupon_code: "",
          bulk_coupon_prefix: "",
          bulk_coupon_length: "",
          min_cart_amount: "",
          applies_to_category: false,
          applies_to_product: false,
          applies_to_syllabus: false,
          applies_to_instrument: false,
          applicable_categories: [],
          applicable_products: [],
          applicable_instruments: [],
          applicable_syllabuses: [],
          applies_for_min_cart_amount: false,
          is_bulk_coupon: false,
          is_active: true,
        };
        this.showCouponCodeField = true;
      }
    },
    getAllScore() {
      score
          .all()
          .then((response) => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
          });
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    showRestrictionToApply() {
      if (this.coupon.product_restriction === 'product'){
        this.show.productShow = true;
        this.show.categoryShow = false;
        this.show.instrumentShow = false;
        this.show.syllabusShow = false;
        this.selectCategories=null;
        this.selectInstruments=null;
        this.selectSyllabus=null;
        this.coupon.applies_to_product=true;
        this.coupon.applies_to_syllabus=false;
        this.coupon.applies_to_instrument=false;
        this.coupon.applies_to_category=false;
        this.categoryList=[];
        this.instrumentList=[];
        this.syllabusList=[];
      }
      else if (this.coupon.product_restriction === 'categories'){
        this.show.productShow = false;
        this.show.categoryShow = true;
        this.show.instrumentShow = false;
        this.show.syllabusShow = false;
        this.selectProducts=null;
        this.selectInstruments=null;
        this.selectSyllabus=null;
        this.coupon.applies_to_syllabus=false;
        this.coupon.applies_to_instrument=false;
        this.coupon.applies_to_product=false;
        this.coupon.applicable_categories=true;
        this.productList=[];
        this.instrumentList=[];
        this.syllabusList=[];
      }
      else if (this.coupon.product_restriction === 'instruments'){
        this.show.productShow = false;
        this.show.categoryShow = false;
        this.show.instrumentShow = true;
        this.show.syllabusShow = false;
        this.selectProducts=null;
        this.selectCategories=null;
        this.selectSyllabus=null;
        this.coupon.applies_to_syllabus=false;
        this.coupon.applies_to_category=false;
        this.coupon.applies_to_product=false;
        this.coupon.applies_to_instrument=true;
        this.productList=[];
        this.categoryList=[];
        this.syllabusList=[];
      }
      else if (this.coupon.product_restriction === 'syllabus'){
        this.show.productShow = false;
        this.show.categoryShow = false;
        this.show.instrumentShow = false;
        this.show.syllabusShow = true;
        this.selectProducts=null;
        this.selectCategories=null;
        this.selectInstruments=null;
        this.coupon.applies_to_instrument=false;
        this.coupon.applies_to_category=false;
        this.coupon.applies_to_product=false;
        this.coupon.applies_to_syllabus=true;
        this.productList=[];
        this.categoryList=[];
        this.instrumentList=[];
      }
      else{
        this.show.productShow = false;
        this.show.categoryShow = false;
        this.show.instrumentShow = false;
        this.show.syllabusShow = false;
        this.coupon.applies_to_instrument=false;
        this.coupon.applies_to_category=false;
        this.coupon.applies_to_product=false;
        this.coupon.applies_to_syllabus=false;
        this.selectProducts=null;
        this.selectCategories=null;
        this.selectInstruments=null;
        this.selectSyllabus=null;
        this.productList=[];
        this.categoryList=[];
        this.instrumentList=[];
        this.productList=[];
      }
      this.coupon.limit_max_candidates=1;
      this.coupon.apply_discount_to_all=false;

      this.$v.$reset();
    },
    categoryToggle() {
      if (this.coupon.applies_to_category){
        this.show.categoryShow = true;
        this.coupon.product_restriction='categories';
      } else {
        this.show.categoryShow = false;
      }
      this.$v.$reset();
    },
    productToggle() {
      if(this.coupon.applies_to_product) {
        this.show.productShow = true;
        this.coupon.product_restriction='product';
      } else{
        this.show.productShow = false;
      }
      this.$v.$reset();
    },
    instrumentToggle() {
      if (this.coupon.applies_to_instrument){
        this.show.instrumentShow = true;
        this.coupon.product_restriction='instruments';
      } else {
        this.show.instrumentShow = false;
      }
      this.$v.$reset();
    },
    syllabusToggle() {
      if (this.coupon.applies_to_syllabus){
        this.show.syllabusShow = true;
        this.coupon.product_restriction='syllabus';
      } else{
        this.show.syllabusShow = false;
      }
      this.$v.$reset();
    },
    cartAmountToggle() {
      if (this.coupon.applies_for_min_cart_amount){
        this.show.cartAmountShow = true;
      } else {
        this.show.cartAmountShow = false;
      }
      this.$v.$reset();
    },
    bulkToggle() {
      if (this.coupon.is_bulk_coupon === true) {
        this.show.bulkShow = true;
        this.showCouponCodeField = false;
      } else {
        this.show.bulkShow = false;
        if (this.edit) {
          this.showCouponCodeField = false;
        }else
        {
          this.showCouponCodeField = true;
        }
      }
      this.$v.$reset();
    },
    removeCategory(index) {
      this.categoryList.splice(index, 1);
    },
    removeProduct(index) {
      this.productList.splice(index, 1);
    },
    removeInstrument(index) {
      this.instrumentList.splice(index, 1);
    },
    removeSyllabus(index) {
      this.syllabusList.splice(index, 1);
    },
    countCouponPrefixLength() {
      let bulk_coupon_prefix_length = parseInt(this.coupon.bulk_coupon_prefix.length) + 1;

      if (
          this.coupon.bulk_coupon_length &&
          parseInt(this.coupon.bulk_coupon_length) < parseInt(bulk_coupon_prefix_length)
      ) {
        this.coupon.bulk_coupon_length = null;
        this.coupon.bulk_coupon_length = bulk_coupon_prefix_length;
        this.errorMessage =
            "Bulk Coupon Length Field must be greater than Bulk Coupon Prefix";
        setTimeout(() => {
          this.errorMessage = null;
        }, 3000);
      }
      if (!this.coupon.bulk_coupon_length) {
        this.coupon.bulk_coupon_length = bulk_coupon_prefix_length;
      }
      if (!this.coupon.bulk_coupon_prefix) {
        this.coupon.bulk_coupon_length = null;
        this.coupon.bulk_coupon_prefix = null;
      }
    },
    showCouponPrompt(value) {
      if (value === 'coupon-prefix') {
        this.promptMessage = 'Nominate the characters that all coupon codes will begin with (eg SANT). This can include upper and lower case, numbers, and symbols.';
        this.dialog = true;
      }
      if (value === 'coupon-length') {
        this.promptMessage = 'The number of characters in the entire coupon code. This includes both your nominated prefix AND the automatically generated unique codes.';
        this.dialog = true;
      }
    },

    validateInputForMaxCandidates(){
      if (this.coupon.limit_max_candidates <= 0) {
        this.coupon.limit_max_candidates = 1;
        this.$snotify.warning("Please enter a positive number greater than 0");
      }
    },
    changeApplyDiscount(){
      this.coupon.limit_max_candidates=1;
    },
  },
  mounted() {
    this.couponCodeDetail();
    this.getAllScore();
  },
  watch: {
    productSearch(val) {
      product
          .search(val)
          .then((response) => {
            this.products = response.data.products;
          })
          .catch((err) => {
          })
          .finally(() => (this.isLoading = false));
    },
    syllabusSearch(val) {
      syllabus
          .search(val)
          .then((response) => {
            this.syllabuses = response.data.syllabuses;
          })
          .catch((err) => {
          })
          .finally(() => (this.isLoading = false));
    },
    instrumentSearch(val) {
      instrument
          .search(val)
          .then((response) => {
            this.instruments = response.data.instruments;
          })
          .catch((err) => {
          })
          .finally(() => (this.isLoading = false));
    },
    categorySearch(val) {
      category
          .search(val)
          .then((response) => {
            this.categories = response.data.categories;
          })
          .catch((err) => {
          })
          .finally(() => (this.isLoading = false));
    },


  },
  computed: {
    couponId() {
      return this.$route.params.couponId;
    },

    currentUser() {
      return this.$store.getters.currentUser;

    },


  },
};
</script>
<style scoped>
/* Custom Styling */
.custom-card {
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
}
.custom-card:hover {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
}


</style>
